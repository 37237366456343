.App {
  /* text-align: center; */
  display: flex;
  background-color: white;
  color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  
}

.Sidemenu {
  /* width: 15.3%; */
  padding: 0.3%;
  background-color: #00ff15;
  float:left;
}


.side-menu-button {
  margin-top: 9%;
  padding: 12px;
  border: 1px solid rgb(119, 117, 117);
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.side-menu-button span {
  text-align: left;
  padding-left: 6px;
  padding-right: 12px;
}

.clear-convo {
  padding: 12px 55px;
  text-align: left;
  transition: ease 0.25s all;
  position: absolute;
  top: 670px;
  left: -15px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
}

.clear-convo:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.clear-convo span {
  text-align: left;
}

.light-mode {
  padding: 12px 77px;
  transition: ease 0.25s all;
  position: absolute;
  top: 720px;
  left: -37px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
}

.light-mode:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.light-mode span {
  text-align: left;
}

.openai-discord {
  padding: 12px 55px;
  text-align: left;
  transition: ease 0.25s all;
  position: absolute;
  top: 770px;
  left: -15px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
}

.openai-discord:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.openai-discord span {
  text-align: left;
}

.u-faq {
  padding: 12px 55px;
  text-align: left;
  transition: ease 0.25s all;
  position: absolute;
  top: 820px;
  left: -15px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
}

.u-faq:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.u-faq span {
  text-align: left;
}

.l-out {
  padding: 12px 55px;
  text-align: left;
  transition: ease 0.25s all;
  position: absolute;
  top: 870px;
  left: -15px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
}

.l-out:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.l-out span {
  text-align: left;
}

.icon1 {
  transform: translate(-16px, 2px);
  transition: transform 1s ease-in-out;
}

.icon2 {
  transform: translate(-16px, 2px);
  transition: transform 1s ease-in-out;
}

.icon3 {
  transform: translate(-16px, 2px);
  transition: transform 1s ease-in-out;
}

.icon4 {
  transform: translate(-16px, 2px);
  transition: transform 1s ease-in-out;
}

.icon5 {
  transform: translate(-16px, 2px);
  transition: transform 1s ease-in-out;
}

.icon6 {
  transform: translate(350px, 34px);
  transition: transform 1s ease-in-out;
}

.icon6:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.chatbox {
 

  width: 100%;
  height: 80%;
  overflow: auto;
}

.chat-icon {
  right: 400px;
  position: absolute;
  top: 20px;
}

.heading1 {
  position: absolute;
  top: 165px;
  left: 755px;
}

.heading2 {
  position: absolute;
  top: 300px;
  left: 450px;
  font-weight: 100;
}

.icon7 {
  transform: translate(56px, -40px);
  transition: transform 1s ease-in-out;
}

.heading3 {
  position: absolute;
  top: 182px;
  left: 760px;
  font-weight: 100;
}

.icon8 {
  transform: translate(45px, 79px);
  transition: transform 1s ease-in-out;
}

.heading4 {
  position: absolute;
  top: 300px;
  left: 1060px;
  font-weight: 100;
}

.icon9 {
  transform: translate(70px, -34px);
  transition: transform 1s ease-in-out;
}

.img1 {
  position: absolute;
  top: 300px;
  left: 600px;
  height: 217px;
  width: 614px;
  display: "inline-block";
}

.l1 {
  position: absolute;
  top: 380px;
  left: 420px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 58px;
}

.l2 {
  position: absolute;
  top: 460px;
  left: 420px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 58px;
}

.l3 {
  position: absolute;
  top: 540px;
  left: 420px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 58px;
}

.c1 {
  position: absolute;
  top: 380px;
  left: 720px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 53px;
}

.c2 {
  position: absolute;
  top: 460px;
  left: 720px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 53px;
}

.c3 {
  position: absolute;
  top: 540px;
  left: 720px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 53px;
}

.li1 {
  position: absolute;
  top: 380px;
  left: 1030px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 53px;
}

.li2 {
  position: absolute;
  top: 460px;
  left: 1030px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 53px;
}
.enter_classcode {
  color: #1d192b;
  text-align: center;
  font-family: Segoe UI;
  font-size: 24px;
  font-style: normal;
  font-weight: 750;
  line-height: 32px;
  /* 133.333% */
}
.li3 {
  position: absolute;
  top: 540px;
  left: 1030px;

  background-color: #3e3f4b;
  display: flex;
  height: 50px;
  width: 220px;

  align-items: center;
  border-radius: 5px;
  padding-left: 53px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.code_div {
  /* width: 284px; */
  height: 72px;
  border-radius: 4px;
  border: 1px solid rgba(121, 116, 126, 0.5);
  font-family: Segoe UI;
}
.code_instruction {
  color: #49454f;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.chat-input-holder {
  /* padding: 29px; */
  position: fixed;
  bottom: 0;
  /* left: 165px; */
  /* right: 0; */
  width: 50%;
}

.belowtext {
  font-family: "Times New Roman";
  font-size: small;
  color: #747477;
}

.chat-input-textarea {
  background-color: #40414f;
  border-radius: 12px;
  border: none;
  border-color: none;
  color: black;
  font-size: 1.1em;

  outline: none;
  box-shadow: 0px 2px 12px 6px #e5e5e5;
  background-color: white;
  border: 1px whitesmoke solid;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-log {
   /* overflow: auto;  */
  text-align: left;
  padding-bottom: 5%;
}

.chat-message.chatgpt {
  /* height: 90px; */
  margin-top: 40px;
 /* background-color: #f7f7f8*/;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #374151;
}

.chat-message-center {
  max-width: 990px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* padding: 19px; */
  line-height: 1.6;
  padding-right: 24px;
  text-justify: inter-word;
  /* justify-content: center; */
  padding-top: 19px;
  padding-bottom: 19px;
  overflow-wrap: anywhere;

  align-items: flex-start; /* Center vertically */
}

.avatar {
  /* background: rgb(204, 255, 255); */
  border-radius: 3px;
  /* horizontal radius / vertical radius */
  width: 63px;
  height: 63px;
 padding-right: 10%;
}

.avatar.chatgpt {
  /* background: #10a37f; */
  border-radius: 3px;
  /* horizontal radius / vertical radius */
  width: 63px;
  height: 63px;
  
}

.message {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #374151;
  transition-delay: 1000s;
  animation-delay: 100s;
max-width: 80%;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid var(--material-theme-sys-light-outline-variant, #C7C5D0);
  background: #FFF;
  
  box-shadow: 1px 1px 6px 6px rgba(0, 0, 0, 0.15) !important;

}
.message2 {  

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #374151;
  transition-delay: 1000s;
  animation-delay: 100s;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid var(--material-theme-sys-light-outline-variant, #C7C5D0);
  background: #FFF;
  max-width:  100%;
  box-shadow: 1px 1px 6px 6px rgba(0, 0, 0, 0.15) !important;
  float: right;
}

.type-writter-disabled-color {
  color: transparent;
}

.typing-indicator {
  background-color: #e6e7ed;
  width: 74px;
  height: 35px;
  border-radius: 50px;
  padding: 5px;
  bottom: 55px;
  display: none;
  margin-left: -590px;
  /* margin-left: 361px; */
  margin-bottom: 70px;
  position: absolute;
  -webkit-animation: 2s bulge infinite ease-out;
  animation: 2s bulge infinite ease-out;
  padding-bottom: 12px;
  padding-right: 0px;
}

/* .typing-indicator {
  background-color: #e6e7ed;
  width: 80px;
  height: 15px;
  border-radius: 50px;
  padding: 20px;
  bottom: 55px;
  display: none;
  margin-left: 17px;
  position: absolute;
  -webkit-animation: 2s bulge infinite ease-out;
  animation: 2s bulge infinite ease-out;
} */
.typing-indicator:before,
.typing-indicator:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #e6e7ed;
}

.typing-indicator:after {
  height: 10px;
  width: 10px;
  left: -10px;
  bottom: -10px;
}

.typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 7px 6px;
  background-color: #9e9ea1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.teepee-button {
  color: #ece5f2;
  font-size: 14px;
  line-height: 20px;
  float: right;
  background: var(--material-theme-sys-light-primary, #5056A9);
  border-radius: 5px;
  font-weight: 600;
    /* 6px */
  ;
  font-weight: normal;
  padding-top: 0.5rem
    /* 8px */
  ;
  padding-bottom: 0.5rem
    /* 8px */
  ;
  padding-left: 1rem
    /* 16px */
  ;
  padding-right: 1rem
    /* 16px */
  ;
}
.typing-indicator span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
  animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
  animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
  animation: 1s blink infinite 0.9999s;
}

.chat-window4 .typing-indicator {
  background-color: #e6e7ed;
  width: 80px;
  height: 15px;
  border-radius: 50px;
  padding: 20px;
  bottom: auto;
  display: none;
  margin-left: 17px;
  margin-top: -75px;
  position: absolute;
  -webkit-animation: 2s bulge infinite ease-out;
  animation: 2s bulge infinite ease-out;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: black;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

.avatar_image {
  height: 100px;

  max-width: none;
  padding-bottom: 20px;
  
}

.icon-div {
  position: "absolute";
  text-align: end;
  position: absolute;
  /* width: 60%; */
  margin: 10px;
  margin-right: 0px;
  height: 0px;
}

.mobile-top-bar {
  position: absolute;
  width: 100%;
  display: flex;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

svg {
  display: inline;
}

.alt {
  border: none;
  background: conic-gradient(from 90deg at var(--b) var(--b),
      #fff 90deg,
      #000 0) calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b)) calc(50% + var(--b));
}

.plus {
  --b: 1.7px;
  width: 35px;
  aspect-ratio: 1;
  border: 10px solid #343541;
  background: conic-gradient(from 90deg at var(--b) var(--b),
      #343541 90deg,
      #fff 0) calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b)) calc(50% + var(--b));
  display: inline-block;
}

.bot-button-styling{
  color: var(--m-3-sys-light-on-primary, #FFF);
text-align: center;
font-family: Open Sans;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
letter-spacing: 0.1px;
}
.heading{
  color: #1D192B;
    text-align: center;
    font-family: Open Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 125% */
}