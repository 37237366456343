@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 420px) {
  /* Your styles for mobile devices go here */
 .right img{
  display: none ;
 }
 .left{
  width: 120vh !important;
  overflow: hidden !important;
 }
  
}
@media screen and (max-width:1080px) {
  .chatbox {
 

    width: 100% !important;
    max-height: 65% !important;
    overflow: auto !important;
  }
}
@media screen and (max-width:830px) {
  .chatbox {
 

    width: 100% !important;
    max-height: 58% !important;
    overflow: auto !important;
  }
}
@media screen and (max-width:570px) {
  .chatbox {
 

    width: 100% !important;
    max-height: 28% !important;
    overflow: auto !important;
  }
}
@media screen and (max-width:280px) {
  .chatbox {
 

    width: 100% !important;
    max-height: 15% !important;
    overflow: auto !important;
  }
}
.bg{
  background:url('./chat_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top: 1px solid grey;
}
.headings{
  color: #1D192B;
    text-align: center;
font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    /* 125% */
}